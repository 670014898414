// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import { useTimeout } from 'rooks';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  getBarStackedOptions,
  getColumnBarOptions,
  getColumnStackedOptions,
  getPieOptions,
} from '@/tableBuilder/tableViewer/chartConfigs';
import { ChartSettingsInterface, defaultSettings } from './ChartSettings.molecule';
import _ from 'lodash';
import { TableChartDataIF } from '@/tableBuilder/tableViewer/ChartUtilities';
import useResizableHighchart from '@/core/hooks/useResizableHighchart.hook';

const DEFAULT_CHART_CONFIG = {
  plotOptions: {
    series: {
      animation: false,
    },
  },
};

interface ChartProps {
  tableData: TableChartDataIF;
  chartColors: string[];
  settings?: ChartSettingsInterface;
  testId: string;
  afterChartUpdate?: () => void;
}

export const CHART_TYPES = [
  { id: 'column', label: 'Column Chart', config: getColumnBarOptions },
  { id: 'bar', label: 'Bar Chart', config: getColumnBarOptions },
  {
    id: 'column_stacked',
    label: 'Column Chart Stacked',
    config: getColumnStackedOptions,
  },
  {
    id: 'bar_stacked',
    label: 'Bar Chart Stacked',
    config: getBarStackedOptions,
  },
  { id: 'pie', label: 'Pie Chart', config: getPieOptions },
];

export const Chart: React.FunctionComponent<ChartProps> = ({
  tableData,
  chartColors,
  settings,
  testId,
  afterChartUpdate,
}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [chart, setChart] = useState<Highcharts.Chart | null>(null);
  const chartElementRef = useRef<HTMLDivElement>(null);

  const options = _.chain(CHART_TYPES)
    .find({ id: settings?.chartType ?? CHART_TYPES[0].id })
    .thru((chartType) => chartType?.config(tableData, settings ?? defaultSettings, chartType.id))
    .merge(DEFAULT_CHART_CONFIG)
    .merge({ colors: chartColors })
    .value();

  const onDestroyChart = () => {
    chartElementRef.current = null;
  };

  // Wait until the next render to ensure the bottom panel is finished rendering
  const { start, clear } = useTimeout(() => {
    setIsInitialized(true);
  }, 1);

  useEffect(() => {
    start();
    return () => clear();
  }, []);

  useResizableHighchart({
    chart,
    chartElementRef,
    setChart,
    onDestroyChart,
  });

  if (!isInitialized || !tableData || _.isEmpty(tableData.values)) {
    return <div />;
  }

  const afterChartCreated = (highchartsChart: Highcharts.Chart) => {
    if (!chart) {
      setChart(highchartsChart);
    }
  };

  afterChartUpdate?.();
  return (
    <div data-testid={testId} className="flexFill flexColumnContainer" ref={chartElementRef}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        callback={afterChartCreated}
        immutable={true}
        containerProps={{ className: 'flexFill' }}
      />
    </div>
  );
};
